import Skeleton from '@mui/material/Skeleton';

export const FormSkeleton = () => {
  return (
    <Skeleton animation="wave" variant="rounded" width="100%" height={294} />
  );
};

export const SummarySkeleton = () => {
  return (
    <Skeleton animation="wave" variant="rounded" width="100%" height={200} />
  );
};
