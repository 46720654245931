import { t } from 'i18next';

import { BrainTypeValues, ResponseLength } from './models';

export const getAIAgentType = (brain_type: BrainTypeValues) => {
  switch (brain_type) {
    case 'activation':
      return t('ai_agents.types.activation.title');
    case 'support':
      return t('ai_agents.types.support.title');
    case 'debt_collection':
      return t('ai_agents.types.debt_collection.title');
    case 'engagement':
      return t('ai_agents.types.engagement.title');
    case 'upsell':
      return t('ai_agents.types.upsell.title');
    default:
      console.warn('Unsupported type:', brain_type);
      return brain_type;
  }
};

/**
 * Converts a given flag emoji string into its corresponding country code.
 *
 * This function takes an emoji flag, converts its Unicode code points to the corresponding
 * country code, then converts it to lowercase.
 *
 * @param {string} flag - The flag emoji to be converted into a country code.
 * @returns {string} The two-letter country code in lowercase.
 *
 * @example
 * // Returns 'gr' for the Greek flag emoji 🇬🇷
 * emojiFlagToCountryCode('🇬🇷');
 */
export const emojiFlagToCountryCode = (flag: string): string => {
  return Array.from(flag, (codeUnit) => codeUnit.codePointAt(0) ?? 0)
    .map((char) => String.fromCharCode(char - 127397).toLowerCase())
    .join('');
};

export const getResponseLength = (length: ResponseLength) => {
  switch (length) {
    case 'short':
      return t('ai_agents.response_length.short');
    case 'medium':
      return t('ai_agents.response_length.medium');
    case 'long':
      return t('ai_agents.response_length.long');
  }
};
