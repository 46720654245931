import { Icon } from '@/models/icon';

export const HumanAgents = ({
  size = 16,
  color = 'var(--icon-default-gray)',
}: Icon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      width={size}
      height={size}
      fill="none"
    >
      <path
        fill={color}
        d="M14.432 13.25c-.952-1.645-2.418-2.825-4.13-3.385a4.5 4.5 0 1 0-4.604 0c-1.712.56-3.179 1.74-4.13 3.385a.5.5 0 1 0 .865.5C3.611 11.715 5.692 10.5 8 10.5c2.308 0 4.39 1.215 5.567 3.25a.502.502 0 0 0 .838.063.499.499 0 0 0 .027-.563ZM4.5 6a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0Z"
      />
    </svg>
  );
};
