import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { FieldError, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Button from '@/components/atoms/Button/Button/Button';
import IconButton from '@/components/atoms/IconButton/IconButton';
import Trash from '@/components/atoms/Icons/Trash';
import Input from '@/components/atoms/Input/Input';
import TextAreaAsInput from '@/components/atoms/Input/TextAreaAsInput';
import useBrains from '@/hooks/useBrains';
import { selectBrainId } from '@/redux/session/selectors';
import { capitalizeFirstLetter } from '@/util/util';

import { PlusIcon } from '../../icons/PlusIcon';
import { FormCard } from '../FormCard/FormCard';
import { NumberIcon } from '../NumberIcon/NumberIcon';

type Form = {
  objections: { message: string; response: string }[];
  message: string;
  response: string;
};

export const Objections = ({ order }: { order: number }) => {
  const brainId = useSelector(selectBrainId);
  const { brain, updateBrain } = useBrains(brainId);
  const { t } = useTranslation();

  // RHF
  const {
    register,
    control,
    handleSubmit,
    reset,
    watch,
    setFocus,
    formState: { errors, isSubmitting, dirtyFields },
  } = useForm<Form>({
    values: {
      objections: brain?.guidelines?.objections || [],
      message: '',
      response: '',
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'objections',
  });

  const message = watch('message');
  const response = watch('response');
  const isDisabled = !(dirtyFields.objections?.length > 0);

  // Handlers
  const onSubmit = (data: Form) => {
    updateBrain({
      brain_id: brainId,
      guidelines: {
        ...brain?.guidelines,
        objections: data.objections,
      },
    });
  };

  const addNewObjection = () => {
    append({ message, response }); // Add new objection
    // Reset initial inputs
    reset(
      { message: '', response: '' },
      // This makes the form dirty in order to enable the submit button
      {
        keepDirty: true,
      }
    );
  };

  return (
    <FormCard id="ai-agent-objections" onSubmit={handleSubmit(onSubmit)}>
      <FormCard.Header
        title={t('ai_agents.knowledge.objections.title')}
        subtitle={t('ai_agents.knowledge.objections.subtitle')}
        icon={
          <NumberIcon
            color="var(--icon-default-blue)"
            size="large"
            number={order}
          />
        }
      />

      <FormCard.Content>
        <Input
          placeholder={t('ai_agents.knowledge.objections.message_placeholder')}
          size="large"
          label={t('ai_agents.knowledge.objections.objection')}
          {...register('message')}
          // Use onKeyDown instead of onKeyUp to prevent the form from submitting
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();

              // Focus on next input
              setFocus('response');
            }
          }}
        />

        <TextAreaAsInput
          placeholder={t('ai_agents.knowledge.objections.response_placeholder')}
          label={t('ai_agents.knowledge.objections.example_response')}
          size="large"
          {...register('response')}
        />

        <Box my="var(--space-24)">
          <Button
            variant="tertiary"
            disabled={!(message && response)}
            onClick={addNewObjection}
            type="button"
          >
            <PlusIcon color="currentColor" />
            {t('ai_agents.knowledge.add_objection')}
          </Button>
        </Box>

        <Box height={1} component="hr" />

        {fields.map((field, index) => {
          return (
            <Stack key={field.id} my="var(--space-16)">
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                mb="var(--space-4)"
              >
                <Typography
                  variant="label-caps-large"
                  color="var(--text-default-gray)"
                >
                  #{index + 1}
                </Typography>

                <IconButton
                  onClick={() => remove(index)}
                  ariaLabel={t('common.delete')}
                >
                  <Trash />
                </IconButton>
              </Box>

              <Input
                size="large"
                {...register(`objections.${index}.message`, {
                  required: t('validation.required'),
                })}
                defaultValue={field.message}
                error={!!errors.objections?.[index]?.message}
                errorMessage={capitalizeFirstLetter(
                  (errors.objections?.[index]?.message as FieldError)?.message
                )}
              />

              <Box mt="var(--space-8)">
                <TextAreaAsInput
                  size="large"
                  {...register(`objections.${index}.response`, {
                    required: t('validation.required'),
                  })}
                  error={!!errors.objections?.[index]?.response}
                  errorMessage={capitalizeFirstLetter(
                    errors.objections?.[index]?.response?.message
                  )}
                  defaultValue={field.response}
                />
              </Box>
            </Stack>
          );
        })}
      </FormCard.Content>

      <FormCard.Footer>
        <Button
          disabled={isDisabled}
          type="submit"
          variant="secondary"
          isLoading={isSubmitting}
        >
          {t('common.save')}
        </Button>
      </FormCard.Footer>
    </FormCard>
  );
};
