import { useCallback, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { callUpload } from '@/api/fetcher';
import { useAccount } from '@/hooks/useAccount';
import { UploadedFile } from '@/models/server';
import { addErrorTemporalToast } from '@/modules/notifications/redux/actions';
import { selectFileUploadMetadata } from '@/redux/session/selectors';
import { delay } from '@/util/util';

const readFileAsync = async (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      resolve(e.target.result as string);
    };

    reader.onerror = reject;
    reader.readAsText(file);
  });

type Props = Partial<{
  stream: boolean;
  uploadPath: string;
  parseJson: boolean;
  isTempFile: boolean;
}>;

type UseFileSubmit = {
  uploadFile: (droppedFile: File) => void;
  uploadFileAsync: (droppedFile: File) => Promise<void>;
  isLoading: boolean;
  error: Error;
  data: UploadedFile;
};

function useFileSubmit({
  stream,
  uploadPath,
  parseJson,
  isTempFile,
}: Props): UseFileSubmit {
  const [queue, setQueue] = useState([]);
  const [error, setError] = useState(null);
  const [response, setResponse] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const { accountId } = useAccount();
  const path = uploadPath ?? `/www/media/${accountId}/upload`;
  const dispatch = useDispatch();
  const metadata = useSelector(selectFileUploadMetadata);

  const handleFile = useCallback(
    async (file) => {
      setError(null);
      try {
        if (stream) {
          const formData = new FormData();
          formData.append('file', file);

          if (isTempFile) {
            formData.append('isTempFile', '{ "isTempFile": "true" }');
          }

          if (!uploadPath && metadata) {
            formData.append('metadata', metadata);
          }

          const resp = await callUpload(path, formData);
          setResponse(resp);
        } else {
          const content = await readFileAsync(file);
          const data = parseJson ? JSON.parse(content) : content;
          setResponse({ name: file.name, data, url: file.path });
        }
      } catch (err) {
        setError(err);
        dispatch(addErrorTemporalToast(err));
      }
    },
    [stream, isTempFile, uploadPath, metadata, path, dispatch, parseJson]
  );

  useEffect(() => {
    const processQueue = async () => {
      if (!isLoading && queue.length > 0) {
        setIsLoading(true);
        const currentFile = queue[0];
        await handleFile(currentFile);
        await delay(150);
        setQueue((prev) => prev.slice(1));
        setIsLoading(false);
      }
    };
    processQueue();
  }, [queue, isLoading, handleFile]);

  const uploadFile = useCallback((file) => {
    setQueue((prev) => [...prev, file]);
  }, []);

  const uploadFileAsync = useCallback(
    async (file: File & { preview: string; path: string }): Promise<void> => {
      return new Promise<void>((resolve) => {
        const checkInterval = setInterval(() => {
          if (!queue.some((queuedFile) => queuedFile.path === file.path)) {
            clearInterval(checkInterval);
            resolve();
          }
        }, 100);

        setQueue((prev) => [...prev, file]);
      });
    },
    [queue]
  );

  return { uploadFile, uploadFileAsync, isLoading, error, data: response };
}

export default useFileSubmit;
