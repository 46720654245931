import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { preferencesEndpoints as endpoints } from '@/api/endpoints';
import { callGet, callPut } from '@/api/fetcher';
import { Preference, Preferences } from '@/models/user';
import {
  addErrorTemporalToast,
  addTemporalToast,
} from '@/modules/notifications/redux/actions';
import { selectAccountSlug } from '@/redux/session/selectors';

import { useAccount } from './useAccount';

export const API = {
  getUserPreferences: async (): Promise<Preference> =>
    callGet(endpoints.userPrefs),
  getAccountPreferences: async (): Promise<Preference> =>
    callGet(endpoints.accountPrefs),
  getAccountUserPreferences: async (): Promise<Preference> =>
    callGet(endpoints.accountUserPrefs),

  updateUserPreferences: async (
    preferences: Preferences
  ): Promise<Preference> => callPut(endpoints.userPrefs, preferences),
  updateAccountPreferences: async (
    preferences: Preferences
  ): Promise<Preference> => callPut(endpoints.accountPrefs, preferences),
  updateAccountUserPreferences: async (
    preferences: Preferences
  ): Promise<Preference> => callPut(endpoints.accountUserPrefs, preferences),
};

export const usePreferences = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const slug = useSelector(selectAccountSlug);
  const dispatch = useDispatch();
  const { accounts } = useAccount();
  const queryConfig = {
    enabled: !!slug,
  };

  const { data: userPreferences } = useQuery<Preference>({
    queryKey: [endpoints.userPrefs],
    queryFn: API.getUserPreferences,
    enabled: !!slug || accounts?.length === 0,
  });

  const { data: accountPreferences, isLoading } = useQuery<Preference>({
    queryKey: [endpoints.accountPrefs, slug],
    queryFn: () => API.getAccountPreferences(),
    ...queryConfig,
  });

  const { data: accountUserPrefs, status: accountUserPrefStatus } =
    useQuery<Preference>({
      queryKey: [endpoints.accountUserPrefs, slug],
      queryFn: API.getAccountUserPreferences,
      ...queryConfig,
    });

  const {
    mutate: updateUserPreferences,
    mutateAsync: updateUserPreferencesAsync,
    status: updateUserPreferencesStatus,
  } = useMutation<Preference, Error, Preferences>({
    mutationFn: API.updateUserPreferences,
    onSuccess: (resp) => {
      queryClient.setQueryData<Preference>([endpoints.userPrefs], (prev) => ({
        ...prev,
        ...resp,
      }));
    },
    onError: (error) => {
      dispatch(addErrorTemporalToast(error));
    },
  });

  const {
    mutate: updateAccountPreferences,
    status: updateAccountPreferencesStatus,
  } = useMutation<Preference, Error, Preferences>({
    mutationFn: API.updateAccountPreferences,
    onSuccess: (resp) => {
      dispatch(addTemporalToast('success', t('accounts.updated')));
      queryClient.setQueryData<Preference>(
        [endpoints.accountPrefs, slug],
        (prev) => ({
          ...prev,
          ...resp,
        })
      );
    },
    onError: (error) => {
      dispatch(addErrorTemporalToast(error));
    },
  });

  const {
    mutate: updateAccountUserPreferences,
    status: updateAccountUserPreferencesStatus,
  } = useMutation<Preference, Error, Preferences>({
    mutationFn: API.updateAccountUserPreferences,
    onSuccess: (resp) => {
      queryClient.setQueryData<Preference>(
        [endpoints.accountUserPrefs, slug],
        (prev) => ({
          ...prev,
          ...resp,
        })
      );
    },
    onError: (error) => {
      dispatch(addErrorTemporalToast(error));
    },
  });

  return {
    userPreferences: userPreferences?.preferences,
    accountPreferences: accountPreferences?.preferences,
    accountUserPrefs: accountUserPrefs?.preferences,
    isLoadingAccountPreferences: isLoading,
    updateUserPreferences,
    updateUserPreferencesAsync,
    updateAccountPreferences,
    updateAccountUserPreferences,
    accountUserPrefStatus,
    updateUserPreferencesStatus,
    updateAccountPreferencesStatus,
    updateAccountUserPreferencesStatus,
  };
};
