import { useCallback, useMemo, useState } from 'react';

import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import Button from '@/components/atoms/Button/Button/Button';
import IconButton from '@/components/atoms/IconButton/IconButton';
import Disconnect from '@/components/atoms/Icons/Disconnect';
import MoreHorizontal from '@/components/atoms/Icons/MoreHorizontal';
import PlusCircle from '@/components/atoms/Icons/PlusCircle';
import { CustomMenu } from '@/components/atoms/Menu/Menu';
import Table from '@/components/atoms/Table/Table';
import { MODAL_FORM } from '@/components/organisms/Modals/ModalConductor';
import useBrains from '@/hooks/useBrains';
import useFeatureFlag from '@/hooks/useFeatureFlag';
import { Brain } from '@/models/brain';
import { Collection } from '@/models/collections';
import { OptionBase } from '@/models/common';
import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import { addErrorTemporalToast } from '@/modules/notifications/redux/actions';
import { popModal, pushModal } from '@/redux/modals/actions';
import { getPermissions } from '@/redux/permissions/selectors';
import { selectAccountSlug } from '@/redux/session/selectors';
import { resolveBrainsPath } from '@/util/util';

import styles from './ConnectedBrains.module.scss';

interface Props {
  collection: Collection;
  collectionId: string;
}
const ConnectedBrains = ({ collection, collectionId }: Props) => {
  const { brainsByLanguage, updateBrain } = useBrains();
  const [selectedBrainId, setSelectedBrainId] = useState<string>(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const slug = useSelector(selectAccountSlug);
  const { ai_agents } = useFeatureFlag();

  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'collections', actions.WRITE)
  );

  const connectedBrains = brainsByLanguage(collection?.language)?.filter(
    (b) => b.collection_id === collectionId
  );

  const brainOptions = useMemo(
    () =>
      brainsByLanguage(collection?.language)
        ?.filter((b) => b.collection_id !== collection?.collection_id)
        ?.map((b) => ({
          label: b.name,
          value: b.brain_id,
        })),
    [brainsByLanguage, collection?.collection_id, collection?.language]
  );

  const handleMenuClick = useCallback((event, i) => {
    setSelectedBrainId(i);
    setAnchorEl(event.currentTarget);
  }, []);

  const handleMenuClose = useCallback(() => {
    setAnchorEl(null);
    setSelectedBrainId(null);
  }, []);

  const handleConnectBrain = () => {
    const renameProps = {
      title: t(`brains.connect_${ai_agents ? 'ai_agent' : 'brain'}`),
      primaryButtonText: t('common.connect'),
      fields: [
        {
          fieldName: 'collection',
          label: t('collections.collection'),
          fieldValue: collection?.name,
          placeholder: t('common.select_an_option'),
          readOnly: true,
        },
        {
          fieldName: 'brains',
          label: t(`common.${ai_agents ? 'ai_agents' : 'brains'}`),
          fieldValue: [],
          type: 'autocomplete',
          placeholder: t('common.select_an_option'),
          options: brainOptions,
          rules: {
            required: true,
          },
        },
      ],

      onCreate: async (data: { collection: string; brains: OptionBase[] }) => {
        try {
          await Promise.all(
            data.brains.map(async (option) => {
              const brain = brainsByLanguage(collection?.language)?.find(
                (brain) => brain.brain_id === option.value
              );
              return updateBrain({
                brain_id: brain.brain_id,
                collection_id: collection.collection_id,
              });
            })
          );
        } catch (e) {
          dispatch(addErrorTemporalToast(e));
        }
        dispatch(popModal());
      },
    };
    dispatch(pushModal(MODAL_FORM, renameProps));
  };

  const getColumns = useMemo(
    () => [
      {
        accessor: 'name',
        Cell: (props) => {
          const row: Brain = props.row.original;

          return (
            <div className={styles.center}>
              <Link
                to={resolveBrainsPath(
                  `/${slug}/brains/${row.brain_id}`,
                  ai_agents
                )}
                className={styles.link}
              >
                {props.value}
              </Link>
            </div>
          );
        },
      },

      {
        Header: '',
        id: 'menu',
        Cell: (props) => {
          const row: Brain = props.row.original;

          return (
            <IconButton
              onClick={(e) => handleMenuClick(e, row.brain_id)}
              ariaLabel="Datasource options"
              ariaHasPopUp
              disabled={!canWrite}
            >
              <MoreHorizontal size={24} />
            </IconButton>
          );
        },
      },
    ],
    [canWrite, handleMenuClick, slug, ai_agents]
  );

  return (
    <div className={styles.container}>
      <div className={styles.headerContainer}>
        <Typography variant="subheading-semi-bold">
          {ai_agents
            ? t('collections.connected_ai_agents')
            : t('collections.conected_brains')}
        </Typography>
        <Button
          onClick={handleConnectBrain}
          variant="tertiary"
          disabled={!canWrite}
        >
          <PlusCircle
            color={
              canWrite ? 'var(--icon-default-blue)' : 'var(--icon-default-gray)'
            }
          />
          {t('common.add')}
        </Button>
      </div>
      {connectedBrains?.length > 0 ? (
        <Table
          data={connectedBrains}
          columns={getColumns}
          noGutters
          hideHeader
        />
      ) : (
        <Typography
          component="p"
          variant="label-regular"
          className={styles.emptyBrain}
        >
          {ai_agents
            ? t('collections.no_connected_ai_agents')
            : t('collections.no_connected_brains')}
        </Typography>
      )}

      <CustomMenu
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleMenuClose}
      >
        <MenuItem
          onClick={() => {
            handleMenuClose();
            updateBrain({
              brain_id: selectedBrainId,
              collection_id: null,
            });
          }}
          className={styles.danger}
        >
          <Disconnect />
          {t('collections.disconnect')}
        </MenuItem>
      </CustomMenu>
    </div>
  );
};

export default ConnectedBrains;
