import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useAccount } from '@/hooks/useAccount';
import useDialogs from '@/hooks/useDialogs';
import useEntities from '@/hooks/useEntities';
import useFeatureFlag from '@/hooks/useFeatureFlag';
import useMembers from '@/hooks/useMembers';
import { useRoles } from '@/hooks/useRoles';
import { Member } from '@/models/member';
import { Role } from '@/models/role';
import { DEFAULT_COLLECTION } from '@/util/constants';
import { resolveBrainsPath } from '@/util/util';

import styles from './TileActivityLog.module.scss';

type UngroupedLogProps = {
  avatar: JSX.Element;
  subject: string;
  verb: string;
  targetType: string;
  targetName: string;
  targetId: string;
  brainId: string;
  brain: string;
  deskId: string;
  desk: string;
  timestamp: string;
  extra: Record<string, unknown>;
};

type GroupedLogProps = {
  handleToggle: () => void;
  toggleGroupedItems: boolean;
  isGrouped: boolean;
  groupedItems: number;
};

type Props = UngroupedLogProps & Partial<GroupedLogProps>;

const formatNames = (names: [string]) => {
  return names.join(', ');
};

const getNames = (arr, extra: UngroupedLogProps['extra']) => {
  if (!arr || !extra) {
    return;
  }

  const id = extra?.role_id || extra?.role_ids ? 'role_id' : 'user_id';
  const ids = (
    extra?.role_ids || extra?.user_ids ? extra[`${id}s`] : extra[id]
  ) as string[];

  const names = arr.reduce((acc: string[], current: Member | Role) => {
    if (ids.includes(current[id])) {
      acc.push(current.name);
    }

    return acc;
  }, []);

  return formatNames(names);
};

const getTargetLink = (
  targetType: UngroupedLogProps['targetType'],
  targetId: UngroupedLogProps['targetId'],
  slug: string,
  brainId: UngroupedLogProps['brainId'],
  deskId: UngroupedLogProps['deskId'],
  ai_agents: boolean
) => {
  switch (targetType) {
    case 'dialogs':
    case 'intents':
    case 'entities':
    case 'webhook':
      return resolveBrainsPath(
        `/${slug}/brains/${brainId}/${targetType}/${targetId}`,
        ai_agents
      );
    case 'rules':
    case 'business_hours':
    case 'integrations':
      return `/${slug}/environments/${deskId}/${targetType}/${targetId}`;
    case 'desks':
      return `/${slug}/environments/${targetId}`;
    case 'brains':
      return resolveBrainsPath(`/${slug}/brains/${targetId}`, ai_agents);
    case 'teams':
      return `/${slug}/account/manage/team-access/`;
    case 'conversations':
      return `/${slug}/chats/${deskId}/conversations/${targetId}`;
    case 'bundles':
      return `/${slug}/environments/${deskId}/context_bundles/${targetId}`;
  }

  return '';
};

// Remove 's' from target type
const getSingularTargetType = (
  targetType: string,
  t: TFunction,
  ai_agents: boolean
) => {
  if (targetType === 'billing') return null;

  if (targetType === 'brains' && ai_agents)
    return t('activityLogs.resources_singular.ai_agents');

  return t(`activityLogs.resources_singular.${targetType}`);
};

const preposition = (verb: string) => {
  return verb.includes('removed') ? 'from' : 'to';
};

const TileActivityLog = ({
  avatar,
  subject,
  verb,
  targetType,
  targetName,
  targetId,
  brainId,
  brain,
  deskId,
  desk,
  timestamp,
  extra,
  handleToggle = () => {},
  isGrouped = false,
  groupedItems = 0,
  toggleGroupedItems = false,
}: Props): JSX.Element => {
  const { t } = useTranslation();
  const { roles } = useRoles();
  const { slug } = useAccount();
  const { members } = useMembers();
  const { entity } = useEntities(brainId, targetId);
  const { ai_agents } = useFeatureFlag();
  const disabledEntity =
    entity?.entity !== targetName && targetType === 'entities';
  const deletedDesk = desk === undefined && targetType === 'desks';
  const deletedBrain = brain === undefined && targetType === 'brains';

  const NEW_ACCOUNT_CREATED = verb === 'created' && targetType === 'accounts';
  const INVITE_RESPONSE = verb === 'responded';

  const { dialog } = useDialogs(
    brainId,
    targetType === 'dialogs' && verb !== 'removed' ? targetId : undefined
  );

  // Hide log if it's a new account created or an invite response
  if (NEW_ACCOUNT_CREATED || INVITE_RESPONSE) {
    return null;
  }

  return (
    <div className={styles.log} data-testid="log">
      <span className={styles.avatar}>{avatar}</span>
      <span>{subject}</span>
      <span>{verb}</span>

      {extra && (extra.user_id || extra.user_ids) && (
        <>
          <span>{getNames(members, extra)}</span>
          <span>{t(`activityLogs.${preposition(verb)}_team`)}</span>
        </>
      )}

      {extra && (extra.role_id || extra.role_ids) && (
        <>
          <span>{getNames(roles, extra)}</span>
          <span>
            {/* TODO: Uncomment when #DEV-2031 is Done */}
            {/* {t(`activityLogs.${preposition(verb)}_member`, [targetName])} */}
            {t(`activityLogs.${preposition(verb)}_a_member`)}
          </span>
        </>
      )}

      {!extra?.role_ids && (
        <>
          {!extra?.user_ids &&
            !extra?.version &&
            verb !== 'removed role' &&
            verb !== 'removed user' && (
              <span>{getSingularTargetType(targetType, t, ai_agents)}</span>
            )}

          {!extra?.version &&
            targetType !== 'users' &&
            verb !== 'removed' &&
            targetType !== 'invitations' &&
            !deletedBrain &&
            !deletedDesk &&
            !disabledEntity &&
            (desk !== undefined ||
              brain !== undefined ||
              targetType === 'teams') && (
              <Link
                className={styles.link}
                to={getTargetLink(
                  targetType,
                  targetId,
                  slug,
                  brainId,
                  deskId,
                  ai_agents
                )}
              >
                {targetName ?? targetId}
              </Link>
            )}

          {(extra?.version ||
            verb === 'removed' ||
            disabledEntity ||
            deletedBrain ||
            (desk === undefined &&
              brain === undefined &&
              targetType !== 'teams') ||
            deletedDesk) && (
            <span className={styles['link--removed']}>
              <>{extra?.version ?? targetName}</>
            </span>
          )}
        </>
      )}

      {targetType === 'dialogs' && (
        <>
          <span>{t('activityLogs.of_collection')}</span>
          <span>{dialog?.collection || DEFAULT_COLLECTION}</span>
        </>
      )}

      {brainId &&
        (targetType !== 'brains' ||
          verb === 'created version' ||
          extra?.version) && (
          <>
            <span>
              {extra?.version
                ? t('activityLogs.of', {
                    0: t(`common.${ai_agents ? 'ai_agent' : 'brain'}`),
                  })
                : brain
                  ? t('activityLogs.in', {
                      0: t(`common.${ai_agents ? 'ai_agent' : 'brain'}`),
                    })
                  : t(
                      `activityLogs.${ai_agents ? 'in_a_deleted_ai_agent' : 'in_a_deleted_brain'}`
                    )}
            </span>
            <Link
              className={styles.link}
              to={resolveBrainsPath(`/${slug}/brains/${brainId}`, ai_agents)}
            >
              {brain}
            </Link>
          </>
        )}

      {deskId && targetType !== 'desks' && (
        <>
          <span>
            {desk
              ? t('activityLogs.in', { 0: t('common.desk') })
              : t('activityLogs.in_a_deleted_desk')}
          </span>

          <Link
            className={styles.link}
            to={resolveBrainsPath(`/${slug}/environments/${deskId}`, ai_agents)}
          >
            {desk}
          </Link>
        </>
      )}

      {targetType === 'invitations' && (
        <>
          <span>{t('activityLogs.to')}</span>
          <span>{targetId}</span>
        </>
      )}

      {isGrouped && toggleGroupedItems && (
        <span
          onClick={handleToggle}
          onKeyDown={handleToggle}
          role="button"
          tabIndex={0}
          className={styles.groupedItems}
        >
          {t('activityLogs.more_actions', { 0: groupedItems })}
        </span>
      )}
      <span className={styles.timestamp}>{timestamp}</span>
    </div>
  );
};

export default TileActivityLog;
