import { useCallback, useState } from 'react';

import Button from '@mui/material/Button/Button';
import Divider from '@mui/material/Divider';
import Grow from '@mui/material/Grow';
import MenuItem from '@mui/material/MenuItem';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import { useQueryClient } from '@tanstack/react-query';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { Avatar } from '@/components/atoms/Avatar/Avatar/Avatar';
import { Badge } from '@/components/atoms/Badge/Badge';
import ArrowDown from '@/components/atoms/Icons/ArrowDown';
import ArrowUp from '@/components/atoms/Icons/ArrowUp';
import Billing from '@/components/atoms/Icons/Billing';
import Create from '@/components/atoms/Icons/Create';
import { InviteMember } from '@/components/atoms/Icons/InviteMember';
import Settings from '@/components/atoms/Icons/Settings';
import SuccessTick from '@/components/atoms/Icons/SuccessTick';
import { CustomMenu } from '@/components/atoms/Menu/Menu';
import StatusBadge from '@/components/atoms/StatusBadge/StatusBadge';
import {
  MODAL_FORM,
  MODAL_MEMBER_INVITE,
} from '@/components/organisms/Modals/ModalConductor';
import { useAccount } from '@/hooks/useAccount';
import { useInvitations } from '@/hooks/useInvitations';
import { actions } from '@/models/permissions';
import { EventName } from '@/models/segment';
import { RootState } from '@/models/state';
import { useBilling } from '@/modules/billing/hooks/useBilling';
import { initDeskBrainIntegration } from '@/modules/onboarding/helper';
import { popModal, pushModal } from '@/redux/modals/actions';
import { setPermissions } from '@/redux/permissions/actions';
import { getPermissions } from '@/redux/permissions/selectors';
import { setAccountId, setAccountSlug } from '@/redux/session/actions';
import { trackEvent } from '@/segment/segment';
import { PLAN_IDS } from '@/util/constants';
import { accountRules } from '@/util/validator';

import styles from './AccountMenu.module.scss';

const CUSTOM_STYLES = {
  '& .MuiMenu-paper': {
    width: 300,
    marginTop: 'var(--space-8)',
  },
};

export const getSwitchAccountLink = (newSlug: string, oldSlug: string) => {
  const oldUrl = window.location.pathname;
  const paths = [
    'brains',
    'ai-agents',
    'collections',
    'broadcasts',
    'integrations',
    'rules',
    'environments',
    'chats',
  ];

  for (const path of paths) {
    if (oldUrl.includes(path)) {
      return `/${newSlug}/${path}`;
    }
  }
  return oldUrl.replace(oldSlug, newSlug);
};
export const AccountMenu = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { handleInvisibleBadge } = useInvitations();
  const dispatch = useDispatch();
  const { createAccount, account, setLastUsedAccountSlug, accounts, slug } =
    useAccount();
  const queryClient = useQueryClient();
  const { billingState } = useBilling();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  // Permissions
  const canReadBilling = useSelector((state: RootState) =>
    getPermissions(state, 'billing', actions.READ)
  );
  const canAssign = useSelector((state: RootState) =>
    getPermissions(state, 'roles', actions.ASSIGN)
  );

  const handleClick = useCallback(
    (event) => {
      setAnchorEl(event.currentTarget);
      handleInvisibleBadge?.setInvisible(true);
    },
    [handleInvisibleBadge]
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleSelection = useCallback(
    (newSlug, showSettings) => {
      trackEvent(EventName.SelectAccount);
      handleClose();

      if (newSlug !== account?.slug) {
        setLastUsedAccountSlug(newSlug);
        // This is used to determine which account to show in the main navigation
        dispatch(setAccountSlug(newSlug));
        dispatch(setPermissions(null));
      }

      if (showSettings) {
        navigate(`/${newSlug}/account/information`);
        return;
      }
      const oldUrl = window.location.pathname;

      if (!oldUrl.includes(account?.slug)) {
        return;
      }
      navigate(getSwitchAccountLink(newSlug, account?.slug));
    },
    [account?.slug, dispatch, handleClose, navigate, setLastUsedAccountSlug]
  );

  const handleCreateAccount = useCallback(
    async (data) => {
      createAccount(data, {
        onSuccess: async (newAccount) => {
          dispatch(setAccountId(newAccount.account_id));

          handleSelection(newAccount.slug, false);

          await initDeskBrainIntegration({
            accountId: newAccount.account_id,
            queryClient,
          });

          dispatch(popModal());
        },
      });
    },
    [createAccount, dispatch, handleSelection, queryClient]
  );

  const showCreateAccount = useCallback(() => {
    trackEvent(EventName.ClickCreateAccount);
    const renameProps = {
      resource: t('accounts.new'),
      fields: [
        {
          fieldName: 'name',
          fieldValue: '',
          rules: accountRules.name,
        },
      ],

      onCreate: handleCreateAccount,
    };
    dispatch(pushModal(MODAL_FORM, renameProps));
  }, [t, handleCreateAccount, dispatch]);

  const getBadgeVariant = (planId) => {
    switch (planId) {
      case PLAN_IDS.ENTERPRISE:
        return 'info';
      case PLAN_IDS.PRO:
        return 'success';
      case PLAN_IDS.STARTER:
        return 'warning';
      case PLAN_IDS.TRIAL:
        return 'neutral';
      default:
        return 'neutral';
    }
  };

  const handleAccountSelection = useCallback(
    (slug: string) => {
      handleSelection(slug, false);
    },
    [handleSelection]
  );

  const handleSettingsSelection = useCallback(
    (id: string) => {
      handleSelection(id, true);
    },
    [handleSelection]
  );

  const handleMemberInviteClick = useCallback(() => {
    dispatch(pushModal(MODAL_MEMBER_INVITE));
    handleClose();
  }, [dispatch, handleClose]);

  const handleCreateAccountClick = useCallback(() => {
    showCreateAccount();
    handleClose();
  }, [showCreateAccount, handleClose]);

  return (
    <>
      <Button
        id="account-button"
        aria-controls={open ? 'account-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        className={styles.triggerButton}
        data-testid="account-menu"
      >
        <Badge
          color="error"
          variant="dot"
          invisible={handleInvisibleBadge?.invisible}
          className={styles.triggerButton__content}
          overlap="rectangular"
        >
          <Avatar size="small" altText={account?.name} src={account?.avatar} />

          {account ? (
            <>
              <Typography
                color="var(--text-default-white)"
                variant="body-regular"
                className={styles.triggerButton__accountName}
              >
                {account?.name}
              </Typography>

              {billingState && (
                <StatusBadge
                  variant={getBadgeVariant(account.plan_id)}
                  label={billingState?.plan_name}
                />
              )}
            </>
          ) : (
            <Skeleton
              animation="wave"
              variant="rectangular"
              width={120}
              height={16}
            />
          )}
        </Badge>

        {open ? (
          <ArrowUp size={8} color="white" />
        ) : (
          <ArrowDown size={18} color="white" />
        )}
      </Button>

      <CustomMenu
        id="account-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        disableRestoreFocus
        MenuListProps={{
          'aria-labelledby': 'account-button',
        }}
        keepMounted
        data-testid="account-menu-popover"
        TransitionComponent={Grow}
        sx={CUSTOM_STYLES}
      >
        {accounts?.map((account) => {
          const isCurrentAccount = account?.slug === slug;
          return (
            <MenuItem
              key={account.slug}
              onClick={() => handleAccountSelection(account.slug)}
              className={cn({
                [styles.selected]: isCurrentAccount,
              })}
            >
              <Avatar
                size="small"
                altText={account?.name}
                src={account?.avatar}
              />
              <span>{account?.name}</span>
              {isCurrentAccount && <SuccessTick strokeWidth={1.5} />}
            </MenuItem>
          );
        })}

        <Divider />

        <MenuItem onClick={() => handleSettingsSelection(slug)}>
          <Settings />
          {t('profile.account_settings')}
        </MenuItem>

        {canReadBilling && (
          <MenuItem
            onClick={() => {
              navigate(`/${slug}/account/billing`);
              handleClose();
            }}
          >
            <Billing color="var(--icon-default-gray)" />
            {t('pages.billing_simple')}
          </MenuItem>
        )}

        {canAssign && (
          <MenuItem onClick={handleMemberInviteClick}>
            <InviteMember />
            {t('members.invite_member')}
          </MenuItem>
        )}

        <Divider />

        <MenuItem
          className={styles.createAccount}
          onClick={handleCreateAccountClick}
        >
          {t('accounts.create')}
          <Create />
        </MenuItem>
      </CustomMenu>
    </>
  );
};
