import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Button from '@/components/atoms/Button/Button/Button';
import Checkmark from '@/components/atoms/Icons/Checkmark';
import { MODAL_CUSTOM_GUIDELINE_CREATE } from '@/components/organisms/Modals/ModalConductor';
import useBrains from '@/hooks/useBrains';
import { pushModal } from '@/redux/modals/actions';
import { selectBrainId } from '@/redux/session/selectors';
import { scrollToElementById } from '@/util/util';

import { CustomGuidelineForm } from './CustomGuidelineForm';
import { Loyalty } from './Loyalty';
import { Objections } from './Objections';
import { ProductFeatures } from './ProductFeaturess';
import { ProductOverview } from './ProductOverview';
import { StaticKnowledge } from './StaticKnowledge';
import { useKnowledge } from '../../hooks/useKnowledge';
import { PlusIcon } from '../../icons/PlusIcon';
import { NumberIcon } from '../NumberIcon/NumberIcon';
import { Summary } from '../Summary/Summary';

export const SellerKnowledge = () => {
  const { t } = useTranslation();
  const { sortedCustomGuidelines } = useKnowledge();

  return (
    <>
      <Box pl="var(--space-16)">
        <Typography
          component="h3"
          variant="h3-semi-bold"
          color="var(--text-default-black)"
        >
          {t('ai_agents.knowledge.title_seller')}
        </Typography>
        <Typography component="p" color="var(--text-default-gray)">
          {t('ai_agents.knowledge.subtitle_customer_support')}
        </Typography>
      </Box>

      <ProductOverview />

      <ProductFeatures />

      <Loyalty />

      <Objections order={4} />

      {sortedCustomGuidelines.map((customGuideline, index) => {
        return (
          <CustomGuidelineForm
            key={customGuideline.created_at}
            {...customGuideline}
            // Order starts from 5
            order={index + 5}
          />
        );
      })}
    </>
  );
};

export const SellerSummary = () => {
  const brainId = useSelector(selectBrainId);
  const { brain } = useBrains(brainId);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { sortedCustomGuidelines, hasNoCustomGuidelines } = useKnowledge();
  const { guidelines: { overview, features, loyalty, objections } = {} } =
    brain || {};
  const buttonText = hasNoCustomGuidelines
    ? t('ai_agents.knowledge.add_guideline')
    : t('ai_agents.knowledge.add_new_guideline');

  return (
    <>
      <Summary>
        <Summary.Step
          onClick={() => scrollToElementById('ai-agent-overview')}
          text={t('ai_agents.knowledge.overview.title')}
          icon={overview ? <Checkmark /> : <NumberIcon number={1} />}
        />
        <Summary.Step
          onClick={() => scrollToElementById('ai-agent-features')}
          text={t('ai_agents.knowledge.features.title')}
          icon={features ? <Checkmark /> : <NumberIcon number={2} />}
        />
        <Summary.Step
          onClick={() => scrollToElementById('ai-agent-loyalty')}
          text={t('ai_agents.knowledge.loyalty.title')}
          icon={loyalty ? <Checkmark /> : <NumberIcon number={3} />}
        />
        <Summary.Step
          text={t('ai_agents.knowledge.objections.title')}
          onClick={() => scrollToElementById('ai-agent-objections')}
          icon={
            objections?.length > 0 ? <Checkmark /> : <NumberIcon number={4} />
          }
        />

        {sortedCustomGuidelines.map((customGuideline, index) => {
          const order = index + 5;
          const icon = customGuideline.body ? (
            <Checkmark />
          ) : (
            <NumberIcon number={order} />
          );
          return (
            <Summary.Step
              key={customGuideline.created_at}
              onClick={() => scrollToElementById(`custom-guideline-${order}`)}
              text={customGuideline.title}
              icon={icon}
            />
          );
        })}
        <Box>
          <Button
            noGutters
            variant="tertiary"
            onClick={(e: React.MouseEvent) => {
              e.stopPropagation();
              e.preventDefault();
              dispatch(pushModal(MODAL_CUSTOM_GUIDELINE_CREATE));
            }}
          >
            <PlusIcon color="currentColor" />
            {buttonText}
          </Button>
        </Box>
      </Summary>

      <StaticKnowledge />

      {/* Hide live instructions until NLP team implments it */}
      {/* <LiveInstructions /> */}
    </>
  );
};
