import { PropsWithChildren, ReactNode } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/system/Stack';
import cn from 'classnames';

import styles from './FormCard.module.scss';

export const FormCard = ({
  children,
  ...props
}: PropsWithChildren<React.HTMLProps<HTMLFormElement>>) => {
  return (
    <form className={cn(styles.form, props.className)} {...props}>
      {children}
    </form>
  );
};

export type CardHeaderProps = {
  icon?: ReactNode;
  title: string;
  subtitle?: string;
  badge?: ReactNode;
};

const CardHeader = ({ icon, title, subtitle, badge }: CardHeaderProps) => {
  return (
    <Stack spacing="var(--space-8)">
      <h3 className={styles.header}>
        {icon}
        <Typography
          className={styles.header__text}
          variant="h3-semi-bold"
          color="var(--text-default-black)"
        >
          {title}
        </Typography>
        {badge && (
          <Box display="flex" ml="auto">
            {badge}
          </Box>
        )}
      </h3>

      {subtitle && (
        <Typography variant="label-regular" color="var(--text-default-gray)">
          {subtitle}
        </Typography>
      )}
    </Stack>
  );
};

const CardContent = ({ children }: PropsWithChildren) => {
  return (
    <Box component="section" my="var(--space-24)">
      {children}
    </Box>
  );
};

const CardFooter = ({ children }: PropsWithChildren) => {
  return <footer className={styles.footer}>{children}</footer>;
};

FormCard.Header = CardHeader;
FormCard.Content = CardContent;
FormCard.Footer = CardFooter;
