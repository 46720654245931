import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Button from '@/components/atoms/Button/Button/Button';
import TextAreaAsInput from '@/components/atoms/Input/TextAreaAsInput';
import useBrains from '@/hooks/useBrains';
import { selectBrainId } from '@/redux/session/selectors';
import { capitalizeFirstLetter } from '@/util/util';
import { LENGTH_L } from '@/util/validator';

import { FormCard } from '../FormCard/FormCard';
import { NumberIcon } from '../NumberIcon/NumberIcon';

type Form = {
  goal: string;
};

export const GoalForm = () => {
  const brainId = useSelector(selectBrainId);
  const { t } = useTranslation();
  const { brain, updateBrain } = useBrains(brainId);
  const isDebtCollection = brain?.brain_type === 'debt_collection';

  const {
    register,
    formState: { errors, isSubmitting, isDirty },
    handleSubmit,
  } = useForm<Form>({
    mode: 'onSubmit',
    values: {
      goal: brain?.guidelines?.goal ?? '',
    },
  });

  const onSubmit = ({ goal }: Form) => {
    // Prevent the form from being submitted if the values have not changed
    if (!isDirty) {
      return;
    }

    updateBrain({
      guidelines: {
        ...brain.guidelines,
        goal,
      },
      brain_id: brainId,
    });
  };

  return (
    <FormCard onSubmit={handleSubmit(onSubmit)} id="ai-agent-goal">
      <FormCard.Header
        title={t('ai_agents.goal.title')}
        subtitle={t('ai_agents.goal.subtitle')}
        icon={
          <NumberIcon
            color="var(--icon-default-blue)"
            size="large"
            number={1}
          />
        }
      />
      <FormCard.Content>
        <TextAreaAsInput
          height="large"
          label={t('ai_agents.goal.label')}
          {...register('goal', {
            maxLength: {
              value: LENGTH_L,
              message: t('validation.max_length', { max: LENGTH_L }),
            },
          })}
          placeholder={t(
            `ai_agents.goal.placeholder${isDebtCollection ? '_debt_collection' : ''}`
          )}
          error={!!errors.goal}
          errorMessage={capitalizeFirstLetter(errors.goal?.message)}
        />
      </FormCard.Content>

      <FormCard.Footer>
        <Button
          disabled={!isDirty}
          type="submit"
          variant="secondary"
          isLoading={isSubmitting}
        >
          {t('common.save')}
        </Button>
      </FormCard.Footer>
    </FormCard>
  );
};
