import React, { useCallback } from 'react';

import Box from '@mui/material/Box';
import { TypographyVariant } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';

import { Banner } from '@/components/atoms/Banner/Banner';
import Button from '@/components/atoms/Button/Button/Button';
import { EditableText } from '@/components/atoms/EditableText/EditableText';
import IconCollection from '@/components/atoms/Icons/Collection';
import LinkIcon from '@/components/atoms/Icons/Link';
import PlusCircle from '@/components/atoms/Icons/PlusCircle';
import Trash from '@/components/atoms/Icons/Trash';
import StatusBadge from '@/components/atoms/StatusBadge/StatusBadge';
import Header from '@/components/organisms/Header/Header';
import {
  MODAL_DELETE,
  MODAL_WARN,
} from '@/components/organisms/Modals/ModalConductor';
import { useAccount } from '@/hooks/useAccount';
import useBrains from '@/hooks/useBrains';
import useCollections from '@/hooks/useCollections';
import useDatasources from '@/hooks/useDatasources';
import useFeatureFlag from '@/hooks/useFeatureFlag';
import { Brain } from '@/models/brain';
import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import { popModal, pushModal } from '@/redux/modals/actions';
import { getPermissions } from '@/redux/permissions/selectors';
import { selectAccountSlug } from '@/redux/session/selectors';
import { getRestrictedNames, resolveBrainsPath } from '@/util/util';
import { getEditableTextValidationSchema, LENGTH_XS } from '@/util/validator';

import { USAGE_PERCENTAGE_THRESHOLD } from '../../Collections/util';
import { getFragmentsPercentage } from '../../Datasource/utils';

const getDisabledMessage = (totalFragmentsReached: boolean, t) => {
  if (totalFragmentsReached) {
    return t('limits.datasources_fragments_reached');
  }
  return t('collections.datasources.no_permission');
};

interface Props {
  onClick: () => void;
}

const CollectionHeader = ({ onClick }: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'collections', actions.WRITE)
  );

  const canDelete = useSelector((state: RootState) =>
    getPermissions(state, 'collections', actions.DELETE)
  );

  const { brainsByLanguage } = useBrains();
  const slug = useSelector(selectAccountSlug);

  const { account } = useAccount();
  const { collectionId } = useParams();
  const {
    collection,
    getStatus,
    updateCollection,
    collections,
    deleteCollectionAsync,
    totalFragmentsReached,
  } = useCollections(collectionId);
  const { datasources } = useDatasources(collectionId);
  const { ai_agents } = useFeatureFlag();

  const canCreateDatasource = canWrite && !totalFragmentsReached;

  const handleCopyLink = useCallback(() => {
    const url = `${window.location.origin}/${slug}/collections/${collectionId}`;
    navigator.clipboard.writeText(url);
  }, [collectionId, slug]);

  const handleDelete = useCallback(async () => {
    const connectedBrains = brainsByLanguage(collection?.language)?.filter(
      (b) => b.collection_id === collectionId
    );

    const brainWarning = (
      <Banner<Partial<Brain>>
        relativePosition
        variant="critical"
        referenceProp="brain_id"
        references={connectedBrains}
        labelKey="name"
        onRefClick={(id) => {
          dispatch(popModal());
          navigate(resolveBrainsPath(`/${slug}/brains/${id}`, ai_agents));
        }}
      >
        <Trans
          i18nKey={
            ai_agents
              ? 'collections.delete_warning_ai_agents'
              : 'collections.delete_warning_brains'
          }
          values={{
            collection: collection?.name,
          }}
        />
      </Banner>
    );

    const warnProps = {
      title: t('common.warning'),
      children: brainWarning,
      primaryButtonText: t('common.close'),
    };

    if (connectedBrains?.length > 0) {
      dispatch(popModal());
      dispatch(pushModal(MODAL_WARN, warnProps));
    } else {
      const warningMessage =
        datasources.length > 0
          ? 'collections.delete_warning_no_empty'
          : 'collections.delete_warning';
      const deleteProps = {
        subtitle: (
          <Trans i18nKey={warningMessage} values={[collection?.name]} />
        ),
        confirm: datasources?.length > 0,
        onDelete: () =>
          deleteCollectionAsync(
            {
              collection_id: collectionId,
              force: datasources?.length > 0,
            },
            {
              onSuccess: () => {
                navigate(`/${slug}/collections`);
                dispatch(popModal());
              },
            }
          ),
      };
      dispatch(pushModal(MODAL_DELETE, deleteProps));
    }
  }, [
    brainsByLanguage,
    collection?.language,
    collection?.name,
    collectionId,
    datasources?.length,
    deleteCollectionAsync,
    dispatch,
    navigate,
    slug,
    t,
    ai_agents,
  ]);

  const handleSubmit = useCallback(
    (text: string) => {
      updateCollection(
        { name: text, collection_id: collectionId },
        {
          onSuccess: () => {
            dispatch(popModal());
          },
        }
      );
    },
    [collectionId, dispatch, updateCollection]
  );

  // Filters out the current collection name from the list of restricted values
  const restrictedValues = getRestrictedNames(collections, collection?.name);

  const validationSchema = getEditableTextValidationSchema(
    LENGTH_XS,
    restrictedValues,
    t('collections.collection_name')
  );

  const editableTextProps = {
    defaultValue: collection?.name,
    onSubmit: handleSubmit,
    variant: 'h2-semi-bold' as TypographyVariant,
    canEdit: canWrite,
    validationSchema,
  };

  const menuItems = [
    {
      name: t('common.copy_link'),
      onClick: handleCopyLink,
      icon: <LinkIcon color="var(--icon-default-gray)" />,
      type: 'copy',
    },
    {
      name: t('common.delete'),
      icon: <Trash />,
      onClick: handleDelete,
      type: 'delete',
      disabled: !canDelete,
    },
  ];

  const percentage = getFragmentsPercentage(
    collection?.fragment_count,
    account?.max_fragments
  );

  const showFragmentBadge = percentage > USAGE_PERCENTAGE_THRESHOLD;

  return (
    <Header>
      <Box display="flex" gap="var(--space-8)">
        <Header.BreadCrumbs />
        <Header.Menu menuItems={menuItems} />
      </Box>
      <Header.Body>
        <Box display="flex" alignItems="center">
          <Header.Title
            icon={<IconCollection />}
            title={
              <EditableText<typeof validationSchema> {...editableTextProps} />
            }
            isLoading={getStatus === 'pending'}
          />
          {showFragmentBadge && (
            <Box mx={1}>
              <StatusBadge
                withIcon={false}
                label={t('limits.data_heavy')}
                variant="warning"
              />
            </Box>
          )}
        </Box>
        <Header.Actions>
          <Tooltip
            arrow
            title={
              !canCreateDatasource
                ? getDisabledMessage(totalFragmentsReached, t)
                : ''
            }
          >
            <span>
              <Button onClick={onClick} disabled={!canCreateDatasource}>
                <PlusCircle
                  color={
                    canCreateDatasource
                      ? 'var(--icon-default-white)'
                      : 'var(--icon-default-gray)'
                  }
                />
                {t('collections.datasources.add_datasource')}
              </Button>
            </span>
          </Tooltip>
        </Header.Actions>
      </Header.Body>
    </Header>
  );
};

export default CollectionHeader;
