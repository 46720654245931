import { useEffect } from 'react';

import { useMutation, useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { callGet, callPut } from '@/api/fetcher';
import { PartialPresence, Presence } from '@/models/presence';
import {
  addErrorTemporalToast,
  addTemporalToast,
} from '@/modules/notifications/redux/actions';
import {
  setAgentStatus,
  setAgentsFromPresence,
} from '@/redux/presence/actions';
import { selectAccountId } from '@/redux/session/selectors';

import useUser from './useUser';

const THIRTY_SECONDS = 30 * 1000;
export const endpoints = {
  presence: '/www/api/v1/presence',
  agentPresence: (id: string) => `/www/api/v1/presence/${id ?? '-'}`,
} as const;

export const API = {
  listPresence: async (): Promise<{ users: Presence[] }> =>
    callGet(endpoints.presence),
  getAgentPresence: async (id: string): Promise<Presence> =>
    callGet(endpoints.agentPresence(id)),
  updateAgentPresence: async ({ agent_id, status }): Promise<Presence> =>
    callPut(endpoints.agentPresence(agent_id), { status }),
} as const;

export const usePresence = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { user } = useUser();
  const accountId = useSelector(selectAccountId);

  const { isSuccess, data } = useQuery<{ users: Presence[] }, Error>({
    queryKey: [endpoints.presence],
    queryFn: () => API.listPresence(),
    refetchInterval: THIRTY_SECONDS,
    enabled: !!user && !!accountId,
  });

  useEffect(() => {
    if (isSuccess) {
      dispatch(setAgentsFromPresence(data));
    }
  }, [isSuccess, data, dispatch]);

  const { mutate: updateAgent, status: updateStatus } = useMutation<
    Presence,
    Error,
    PartialPresence
  >({
    mutationFn: API.updateAgentPresence,
    onSuccess: (resp) => {
      dispatch(setAgentStatus(resp));
      dispatch(
        addTemporalToast(
          'success',
          t('temporal_toast.agent_updated', { 0: resp.status })
        )
      );
    },
    onError: (error) => {
      dispatch(addErrorTemporalToast(error));
    },
  });

  return {
    updateAgent,
    updateStatus,
  };
};
