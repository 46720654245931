import { Icon } from '@/models/icon';

const Access = ({ size = 16, color = 'currentColor' }: Icon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      width={size}
      height={size}
      fill="none"
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M5 7V4.778c0-.737.316-1.443.879-1.964A3.126 3.126 0 0 1 8 2c.796 0 1.559.293 2.121.814.563.52.879 1.227.879 1.964V7M4.111 7h7.778C12.503 7 13 7.57 13 8.273v4.454C13 13.43 12.502 14 11.889 14H4.11C3.497 14 3 13.43 3 12.727V8.273C3 7.57 3.497 7 4.111 7Z"
      />
    </svg>
  );
};
export default Access;
