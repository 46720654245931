import {
  useEffect,
  useState,
  useCallback,
  useRef,
  useMemo,
  useContext,
} from 'react';

import isNil from 'lodash/isNil';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import useFileSubmit from '@/components/atoms/FileUpload/useFileSubmit';
import useDebounce from '@/hooks/useDebounce';
import { useMacros } from '@/hooks/useMacros';
import useMessages from '@/hooks/useMessages';
import { useConversationsNew } from '@/modules/humanChat/hooks/useConversationsNew';
import { addTemporalToast } from '@/modules/notifications/redux/actions';
import {
  setFocusIndex,
  setFilteredOptions,
  decrementFocusIndex,
  incrementFocusIndex,
} from '@/redux/macros/actions';
import {
  filteredOptionsSelector,
  focusIndexSelector,
} from '@/redux/macros/selectors';
import { selectDeskId, selectSessionId } from '@/redux/session/selectors';
import { MAX_UPLOAD_SIZE } from '@/util/constants';
import {
  delay,
  selectType,
  isKeyEnter,
  preventClickThrough,
} from '@/util/util';

import { ChatContext } from '../../context';
const MAX_FILES = 5;
export type UploadedData = { name: string; url: string; id?: string };

/**
 * Provides chat box state and actions.
 */
export const useChatBox = () => {
  const deskId = useSelector(selectDeskId);
  const options = useSelector(filteredOptionsSelector);
  const focusIndex = useSelector(focusIndexSelector);
  const dispatch = useDispatch();
  const { macros } = useMacros();
  const { uploadFileAsync, data, isLoading, error } = useFileSubmit({
    isTempFile: true,
    stream: true,
  });

  const sessionId = useSelector(selectSessionId);
  const [input, setInput] = useState('');
  const [showQuickResponses, setShowQuickResponses] = useState(false);
  const [files, setFiles] = useState([]);
  const [uploadedData, setUploadedData] = useState<UploadedData[]>([]);
  const [uploading, setUploading] = useState<boolean>(false);

  const { updateTyping, conversation, getStatus, updateConversation } =
    useConversationsNew({ deskId, sessionId });
  const { t } = useTranslation();
  const { createMessage, createStatus } = useMessages(deskId, sessionId);
  const inputRef = useRef<HTMLDivElement>(null);
  const typingRef = useRef<boolean>(false);
  const { chat, setChat } = useContext(ChatContext);
  const debouncedInput = useDebounce(input, 1000);

  useEffect(() => {
    if (data && !isLoading) {
      setUploadedData((prev) => [...prev, { name: data.name, url: data.url }]);
    }
  }, [data, isLoading]);

  useEffect(() => {
    if (error) {
      setUploadedData([]);
      setFiles([]);
    }
  }, [error, dispatch]);

  const onDrop = useCallback(
    async (acceptedFiles, rejectedFiles) => {
      const canUpload = uploadedData.length + acceptedFiles.length <= MAX_FILES;

      if (!acceptedFiles.length || !canUpload) {
        const errorMessage = !canUpload
          ? t('chatBox.upload_error', { max_files: MAX_FILES })
          : rejectedFiles[0]?.errors?.[0]?.message || 'Unknown error';
        dispatch(addTemporalToast('error', errorMessage));
        return;
      }

      setUploading(true);

      try {
        const newFiles = acceptedFiles.map((file) => {
          const enhancedFile = new File([file], file.name, {
            type: file.type,
            lastModified: file.lastModified,
          });

          return Object.assign(enhancedFile, {
            preview: URL.createObjectURL(file),
            path: file.name,
          });
        });

        setFiles((oldFiles) => [...oldFiles, ...newFiles]);

        for (const file of acceptedFiles) {
          await uploadFileAsync(file);
        }
      } catch (error) {
        dispatch(addTemporalToast('error', error.message));
      } finally {
        setUploading(false);
      }
    },
    [uploadedData.length, t, dispatch, uploadFileAsync]
  );

  const dropzoneConfig = useMemo(
    () => ({
      maxFiles: MAX_FILES,
      maxSize: MAX_UPLOAD_SIZE,
      disabled: uploading,
      multiple: true,
      noDragEventsBubbling: true,
      onDrop,
      noClick: true,
      noKeyboard: true,
    }),
    [uploading, onDrop]
  );

  const { getRootProps, getInputProps, open, isDragActive } =
    useDropzone(dropzoneConfig);

  useEffect(() => {
    if (!isNil(debouncedInput) && typingRef.current) {
      updateTyping('stop');
      typingRef.current = false;
    }
  }, [debouncedInput, updateTyping]);

  const filteredOptions = useMemo(
    () =>
      macros?.filter((option) =>
        `${option?.name} ${option?.text}`
          .toLowerCase()
          .includes(input.toLowerCase().replace('/', ''))
      ),
    [input, macros]
  );

  const updateInputText = useCallback(
    (text = '') => {
      if (inputRef.current) {
        inputRef.current.innerText = text;
      }
      setInput(text);
      setChat({ ...chat, [sessionId]: text });
    },
    [chat, sessionId, setChat]
  );

  useEffect(() => {
    if (conversation?.status === 'closed' && input !== '') {
      updateInputText('');
      setFiles([]);
    }
  }, [conversation?.status, input, sessionId, setFiles, updateInputText]);

  useEffect(() => {
    dispatch(setFilteredOptions(filteredOptions));
    dispatch(setFocusIndex(0));
  }, [dispatch, filteredOptions]);

  const sendUploadedFiles = useCallback(async () => {
    try {
      setUploading(true);
      for (const data of uploadedData) {
        createMessage({
          text: data.name,
          body: { type: selectType(data.url), url: data.url },
        });
        await delay(100);
      }
    } catch (error) {
      console.error('Error uploading files:', error);
    } finally {
      setUploading(false);
    }
  }, [uploadedData, createMessage]);

  const handleClick = useCallback(
    async (e) => {
      if (isLoading) {
        return;
      }

      e.preventDefault();

      const hasInput = input && input.trim() !== '';
      if (hasInput) {
        createMessage(
          { text: input.trim() },
          {
            onSuccess: () => {
              updateInputText('');
            },
            onSettled: sendUploadedFiles,
          }
        );
      } else if (uploadedData.length > 0) {
        await sendUploadedFiles();
      }

      setUploadedData([]);
      setFiles([]);

      if (!isNil(input)) {
        updateTyping('stop');
        typingRef.current = false;
      }
    },
    [
      isLoading,
      input,
      uploadedData.length,
      createMessage,
      sendUploadedFiles,
      updateInputText,
      updateTyping,
    ]
  );

  const handleKeyDown = useCallback(
    (e) => {
      if (isKeyEnter(e) && !e.shiftKey && !showQuickResponses) {
        e.preventDefault();
      }
    },
    [showQuickResponses]
  );

  const handleKeyPress = useCallback(
    async (event) => {
      if (isKeyEnter(event) && showQuickResponses) {
        event.preventDefault();
        updateInputText(options[focusIndex].text);
        setShowQuickResponses(false);
        return;
      }

      const canSendMessage =
        isKeyEnter(event) &&
        !event.shiftKey &&
        !showQuickResponses &&
        !(getStatus === 'pending' || uploading || createStatus === 'pending');

      if (canSendMessage) {
        event.preventDefault();
        handleClick(event);
        return;
      }

      if (event.code === 'Slash' && input?.length === 1) {
        setShowQuickResponses(true);
        return;
      }

      const shouldHideQuickResponses =
        showQuickResponses &&
        event.code === 'Backspace' &&
        (options?.length === 0 || input?.length === 1);

      if (shouldHideQuickResponses) {
        setShowQuickResponses(false);
        return;
      }

      if (showQuickResponses) {
        if (event.code === 'ArrowUp') {
          dispatch(decrementFocusIndex());
        } else if (event.code === 'ArrowDown') {
          dispatch(incrementFocusIndex());
        }
      }
    },
    [
      showQuickResponses,
      getStatus,
      uploading,
      createStatus,
      input?.length,
      updateInputText,
      options,
      focusIndex,
      handleClick,
      dispatch,
    ]
  );

  const handleChange = useCallback(() => {
    if (conversation?.status === 'closed' && conversation?.state?.active) {
      updateConversation({ status: 'open' });
    }
    if (!typingRef.current) {
      updateTyping('start');
      typingRef.current = true;
    }
    if (showQuickResponses && /\n/.test(inputRef.current?.innerText || '')) {
      return;
    }

    if (inputRef.current) {
      const trimmedInput = inputRef.current.innerText.trim();
      setInput(trimmedInput);
      setChat({ ...chat, [sessionId]: inputRef.current.innerText });
    }
  }, [
    chat,
    conversation?.state?.active,
    conversation?.status,
    sessionId,
    setChat,
    showQuickResponses,
    updateConversation,
    updateTyping,
  ]);

  const changeInputTo = useCallback(
    (text: string) => {
      updateInputText(text);
      setTimeout(() => inputRef.current?.focus(), 0);
    },
    [updateInputText]
  );

  const handleOnRemoveFile = useCallback((e, index: number, name: string) => {
    preventClickThrough(e);
    setFiles((files) => files.filter((_, i) => i !== index));
    setUploadedData((urls) => urls.filter((url) => url.name !== name));
  }, []);

  const handlePaste = useCallback(
    async (e) => {
      e.preventDefault();
      const text = e.clipboardData?.getData('text/plain');
      // Text handling
      if (text) {
        document.execCommand('insertText', false, text.trim());
        return;
      }

      // Image handling
      try {
        if (isLoading) {
          return;
        }
        const clipboardItems = await navigator.clipboard.read();
        for (const clipboardItem of clipboardItems) {
          for (const type of clipboardItem.types) {
            if (type.startsWith('image')) {
              if (uploadedData.length + 1 > MAX_FILES) {
                dispatch(
                  addTemporalToast(
                    'error',
                    t('chatBox.upload_error', { max_files: MAX_FILES })
                  )
                );
                return;
              }
              setUploading(true);
              const blob = await clipboardItem.getType(type);
              const extension = blob.type.split('/')[1] || 'jpg';
              const file = new File([blob], `${Date.now()}.${extension}`, {
                type: blob.type,
              });

              const source = {
                preview: URL.createObjectURL(file),
                path: file.name,
              };

              Object.assign(file, source);

              setFiles((prev) => {
                return [...prev, file];
              });

              await uploadFileAsync(file);

              setUploading(false);
            }
          }
        }
      } catch (readErr) {
        console.warn('[ChatBox]Failed to read clipboard items', readErr);
      }
    },
    [dispatch, isLoading, t, uploadFileAsync, uploadedData.length]
  );

  return {
    files,
    isClosed: conversation?.state?.active && conversation?.status === 'closed',
    isActive: conversation?.state?.active,
    isLoading:
      getStatus === 'pending' ||
      uploading ||
      createStatus === 'pending' ||
      isLoading,
    isButtonDisabled:
      ((input?.length === 0 || isNil(input)) && !uploadedData.length) ||
      uploading ||
      isLoading,
    isUnavailable: !conversation?.state?.active && getStatus !== 'pending',
    inputRef,
    filteredOptions,
    showQuickResponses,
    handleClick,
    handleChange,
    changeInputTo,
    handleKeyPress,
    handleKeyDown,
    setShowQuickResponses,
    handleOnRemoveFile,
    handlePaste,
    getRootProps,
    getInputProps,
    open,
    isDragActive,
  };
};
