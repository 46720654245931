import Typography, { TypographyProps } from '@mui/material/Typography';

type NumberIconProps = {
  number: number;
  size?: 'small' | 'large';
  color?: string;
} & Omit<TypographyProps, 'children'>;

const COMMON_STYLES = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: '1px solid var(--icon-default-black)',
  borderRadius: '50%',
  flexShrink: 0,
  lineHeight: 1,
  boxSizing: 'border-box',
} as const;

export const NumberIcon = ({
  number,
  size = 'small',
  color = 'var(--icon-default-black)',
  sx,
  ...rest
}: NumberIconProps) => {
  const isSingleDigit = number >= 0 && number <= 9;

  // Dynamic styles based on the size prop
  const dimensions = {
    small: {
      size: 14,
      fontSize: isSingleDigit ? 11 : 8,
      borderWidth: 1,
      fontWeight: '400',
    },
    large: {
      size: 20,
      fontSize: isSingleDigit ? 14 : 10,
      borderWidth: 1.5,
      fontWeight: '600',
    },
  };

  const styles = dimensions[size];

  return (
    <Typography
      sx={{
        ...COMMON_STYLES,
        width: `${styles.size}px`,
        height: `${styles.size}px`,
        fontSize: `${styles.fontSize}px`,
        borderColor: color,
        color,
        borderWidth: styles.borderWidth,
        fontWeight: styles.fontWeight,
        ...sx,
      }}
      {...rest}
    >
      {number}
    </Typography>
  );
};
