import { useState } from 'react';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { Banner } from '@/components/atoms/Banner/Banner';
import { Datasource } from '@/components/atoms/Icons/Datasource';
import Disconnect from '@/components/atoms/Icons/Disconnect';
import { CustomMenu } from '@/components/atoms/Menu/Menu';
import { MODAL_FORM } from '@/components/organisms/Modals/ModalConductor';
import useBrains from '@/hooks/useBrains';
import useCollections from '@/hooks/useCollections';
import useHomeCheckList, {
  AccountUserPrefsEnum,
} from '@/hooks/useHomeCheckList';
import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import { popModal, pushModal } from '@/redux/modals/actions';
import { getPermissions } from '@/redux/permissions/selectors';
import { selectAccountSlug, selectBrainId } from '@/redux/session/selectors';

import { InfoIcon } from '../../icons/InfoIcon';
import { Summary } from '../Summary/Summary';

export const StaticKnowledge = () => {
  // Redux selectors
  const brainId = useSelector(selectBrainId);
  const slug = useSelector(selectAccountSlug);
  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'collections', actions.WRITE)
  );

  // Custom hooks
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { brain, updateBrain } = useBrains(brainId);
  const { markAsComplete } = useHomeCheckList();
  const { collection, collectionsByLanguage } = useCollections(
    brain?.collection_id
  );

  // Local state
  const [anchorEl, setAnchorEl] = useState(null);

  // Local variables
  const open = Boolean(anchorEl);
  const collections = collectionsByLanguage(brain?.language) || [];
  const options = collections.map((collection) => ({
    label: collection.name,
    value: collection.collection_id,
  }));

  // Handlers
  const handleConnectCollectionClick = () => {
    const renameProps = {
      title: t('collections.connect_collection'),
      primaryButtonText: t('common.connect'),
      fields: [
        {
          fieldName: 'collections',
          fieldValue: '',
          type: 'select',
          placeholder: t('common.select_an_option'),
          options,
          rules: {
            required: true,
          },
        },
      ],
      banner: (
        <Banner relativePosition variant="neutral">
          {t('collections.connect_collection_banner1')}
          <Link
            to={`/${slug}/collections`}
            onClick={() => {
              dispatch(popModal());
            }}
          >
            {t('common.collections')}
          </Link>
          {t('collections.connect_collection_banner2')}
        </Banner>
      ),
      onCreate: (data: { collections: string }) => {
        updateBrain(
          {
            brain_id: brainId,
            collection_id: data.collections,
          },
          {
            onSuccess: () => {
              markAsComplete(AccountUserPrefsEnum.CONNECT_BRAIN);
              dispatch(popModal());
            },
          }
        );
      },
    };
    dispatch(pushModal(MODAL_FORM, renameProps));
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const renderContent = () => {
    // No connected collection
    if (brain && !brain.collection_id) {
      return (
        <Box
          component="button"
          display="flex"
          alignItems="center"
          gap="var(--space-8)"
          bgcolor="transparent"
          color="var(--text-default-blue)"
          onClick={handleConnectCollectionClick}
        >
          <Datasource size={16} color="currentColor" />
          <Typography color="currentColor">
            {t('collections.connect_collection')}
          </Typography>
        </Box>
      );
    } // Connecting collection
    else if (collection) {
      return (
        <>
          <Typography
            display="flex"
            alignItems="center"
            gap="var(--space-8)"
            color="var(--text-default-blue)"
          >
            <Datasource size={16} color="currentColor" />
            <Link to={`/${slug}/collections/${collection?.collection_id}`}>
              {collection?.name}
            </Link>
            <IconButton
              sx={{
                marginLeft: 'auto',
              }}
              onClick={handleMenuClick}
              disabled={!canWrite}
            >
              <MoreVertIcon fontSize="small" />
            </IconButton>
          </Typography>

          <CustomMenu
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleMenuClose}
          >
            <MenuItem
              onClick={() => {
                handleMenuClose();
                updateBrain({
                  brain_id: brainId,
                  collection_id: null,
                });
              }}
            >
              <Disconnect />
              {t('collections.disconnect')}
            </MenuItem>
          </CustomMenu>
        </>
      );
    }

    // Loading state
    return <CircularProgress size={20} />;
  };

  return (
    <>
      <Summary
        title={t('ai_agents.knowledge.static_knowledge').toUpperCase()}
        icon={
          <Tooltip
            arrow
            title={t('ai_agents.knowledge.static_knowledge_tooltip')}
          >
            <InfoIcon color="var(--icon-default-gray)" />
          </Tooltip>
        }
      >
        {renderContent()}
      </Summary>
    </>
  );
};
