import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Checkmark from '@/components/atoms/Icons/Checkmark';
import useBrains from '@/hooks/useBrains';
import { selectBrainId } from '@/redux/session/selectors';
import { scrollToElementById } from '@/util/util';

import { GoalForm } from './GoalForm';
import { ResponseLengthForm } from './ResponseLengthForm';
import { ToneOfVoiceForm } from './ToneOfVoiceForm';
import { getResponseLength } from '../../helper';
import { Badge } from '../Badge/Badge';
import { NumberIcon } from '../NumberIcon/NumberIcon';
import { Summary } from '../Summary/Summary';

export const SellerSetup = ({ isDebtCollection = false }) => {
  return (
    <>
      <GoalForm />
      <ToneOfVoiceForm number={2} isDebtCollection={isDebtCollection} />
      <ResponseLengthForm number={3} />
    </>
  );
};

export const SellerSummary = ({ isDebtCollection = false }) => {
  const { t } = useTranslation();
  const brainId = useSelector(selectBrainId);
  const { brain } = useBrains(brainId);
  const { guidelines } = brain || {};
  const hasGoal = !!guidelines?.goal;
  const hasToneOfVoice = typeof guidelines?.tone_level === 'number';
  const hasResponseLength = !!guidelines?.response_size;

  const getCreativityLevel = (number: number) => {
    switch (number) {
      case -1:
        return isDebtCollection
          ? t('ai_agents.tone_level.creative')
          : t('ai_agents.tone_level.empathetic');
      case 0:
        return t('ai_agents.tone_level.neutral');
      case 1:
        return isDebtCollection
          ? t('ai_agents.tone_level.firm')
          : t('ai_agents.tone_level.matter_of_fact');
      case 2:
        return isDebtCollection
          ? t('ai_agents.tone_level.urgent')
          : t('ai_agents.tone_level.professional');
    }
  };

  return (
    <Summary>
      <Summary.Step
        text={t('ai_agents.goal.title')}
        icon={hasGoal ? <Checkmark /> : <NumberIcon number={1} />}
        onClick={() => scrollToElementById('ai-agent-goal')}
      />

      <Summary.Step
        text={t('ai_agents.tone_level.title')}
        icon={hasToneOfVoice ? <Checkmark /> : <NumberIcon number={2} />}
        onClick={() => scrollToElementById('ai-agent-tone-of-voice')}
      >
        {hasToneOfVoice && (
          <Badge
            ml="var(--space-4)"
            badgeVariant="blue"
            text={getCreativityLevel(guidelines.tone_level)}
          />
        )}
      </Summary.Step>

      <Summary.Step
        text={t('ai_agents.response_length.title')}
        icon={hasResponseLength ? <Checkmark /> : <NumberIcon number={3} />}
        onClick={() => scrollToElementById('ai-agent-response-length')}
      >
        {hasResponseLength && (
          <Badge
            ml="var(--space-4)"
            badgeVariant="blue"
            text={getResponseLength(guidelines.response_size)}
          />
        )}
      </Summary.Step>
    </Summary>
  );
};
