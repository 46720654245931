import IconButton from '@mui/material/IconButton';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Button from '@/components/atoms/Button/Button/Button';
import Trash from '@/components/atoms/Icons/Trash';
import TextAreaAsInput from '@/components/atoms/Input/TextAreaAsInput';
import { MODAL_DELETE } from '@/components/organisms/Modals/ModalConductor';
import useBrains from '@/hooks/useBrains';
import { CustomGuideline } from '@/models/brain';
import { popModal, pushModal } from '@/redux/modals/actions';
import { selectBrainId } from '@/redux/session/selectors';
import { capitalizeFirstLetter } from '@/util/util';

import { FormCard } from '../FormCard/FormCard';
import { NumberIcon } from '../NumberIcon/NumberIcon';

type Form = {
  body: string;
};

type CustomGuidelineFormProps = CustomGuideline & {
  order: number;
};

export const CustomGuidelineForm = ({
  title,
  subtitle,
  body,
  created_at,
  order,
}: CustomGuidelineFormProps) => {
  const brainId = useSelector(selectBrainId);
  const { brain, updateBrain } = useBrains(brainId);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // RHF
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting, isDirty },
  } = useForm<Form>({
    mode: 'onSubmit',
    values: {
      body: body || '',
    },
  });

  // Delete custom guideline based on the created_at
  // because title, subtitle and body can be duplicate
  const deleteCustomGuideline = () => {
    updateBrain({
      brain_id: brainId,
      guidelines: {
        ...brain?.guidelines,
        custom_guidelines: brain?.guidelines?.custom_guidelines?.filter(
          (guideline) => guideline.created_at !== created_at
        ),
      },
    });
  };

  const handleClick = () => {
    const deleteProps = {
      subtitle: (
        <Trans i18nKey="ai_agents.modal.warn_message" values={{ 0: title }} />
      ),
      confirm: true,
      secondaryButtonText: t('common.cancel'),
      onDelete: () => {
        deleteCustomGuideline();
        dispatch(popModal());
      },
    };

    dispatch(pushModal(MODAL_DELETE, deleteProps));
  };

  const onSubmit = (data: Form) => {
    // Loop all custom guidelines and find the one with the same created_at
    // and update it with the new data
    const customGuidelines = brain?.guidelines?.custom_guidelines || [];
    const index = customGuidelines.findIndex(
      (guideline) => guideline.created_at === created_at
    );

    if (index > -1) {
      customGuidelines[index] = {
        ...customGuidelines[index],
        title,
        subtitle,
        body: data.body,
        created_at,
      };
    } else {
      customGuidelines.push({
        title,
        subtitle,
        body: data.body,
        created_at,
      });
    }

    updateBrain({
      brain_id: brainId,
      guidelines: {
        ...brain?.guidelines,
        custom_guidelines: customGuidelines,
      },
    });
  };

  return (
    <FormCard
      onSubmit={handleSubmit(onSubmit)}
      id={`custom-guideline-${order}`}
    >
      <FormCard.Header
        title={title}
        subtitle={subtitle}
        icon={
          <NumberIcon
            color="var(--icon-default-blue)"
            size="large"
            number={order}
          />
        }
        badge={
          <IconButton onClick={handleClick}>
            <Trash />
          </IconButton>
        }
      />

      <FormCard.Content>
        <TextAreaAsInput
          height="large"
          label={t('ai_agents.navigation.overview')}
          {...register('body', {
            maxLength: {
              value: 250,
              message: t('validation.max_length', { max: 250 }),
            },
          })}
          placeholder={t('ai_agents.knowledge.loyalty.placeholder')}
          error={!!errors.body}
          errorMessage={capitalizeFirstLetter(errors.body?.message)}
        />
      </FormCard.Content>

      <FormCard.Footer>
        <Button
          disabled={!isDirty}
          type="submit"
          variant="secondary"
          isLoading={isSubmitting}
        >
          {t('common.save')}
        </Button>
      </FormCard.Footer>
    </FormCard>
  );
};
