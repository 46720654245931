import Skeleton from '@mui/material/Skeleton';
import { useSelector } from 'react-redux';

import useBrains from '@/hooks/useBrains';
import { selectBrainId } from '@/redux/session/selectors';

import {
  CustomerSupportKnowledge,
  CustomerSupportSummary,
} from './CustomerSupportKnowledge';
import {
  DebtCollectionKnowledge,
  DebtCollectionSummary,
} from './DebtCollectionKnowledge';
import { SellerKnowledge, SellerSummary } from './SellerKnowledge';
import { FormSkeleton, SummarySkeleton } from '../Skeletons/Skeletons';

import styles from './Knowledge.module.scss';

export const Knowledge = () => {
  const brainId = useSelector(selectBrainId);
  const { brain, getStatus } = useBrains(brainId);
  const isCustomerSupport = brain?.brain_type === 'support';
  const isDebtCollection = brain?.brain_type === 'debt_collection';
  const showSellerKnowledge = !isCustomerSupport && !isDebtCollection;

  const renderMainContent = () => {
    if (getStatus === 'pending') {
      return (
        <>
          <Skeleton
            animation="wave"
            variant="rounded"
            width="100%"
            height={41}
          />
          <FormSkeleton />
          <FormSkeleton />
          <FormSkeleton />
        </>
      );
    }

    if (isCustomerSupport) {
      return <CustomerSupportKnowledge />;
    }

    if (isDebtCollection) {
      return <DebtCollectionKnowledge />;
    }

    if (showSellerKnowledge) {
      return <SellerKnowledge />;
    }
  };

  const renderAsideContent = () => {
    if (getStatus === 'pending') {
      return <SummarySkeleton />;
    }

    if (isCustomerSupport) {
      return <CustomerSupportSummary />;
    }

    if (isDebtCollection) {
      return <DebtCollectionSummary />;
    }

    if (showSellerKnowledge) {
      return <SellerSummary />;
    }
  };

  return (
    <div className={styles.knowledge}>
      <div className={styles.knowledge__main}>{renderMainContent()}</div>

      <div className={styles.knowledge__summary}>{renderAsideContent()}</div>
    </div>
  );
};
