import Typography from '@mui/material/Typography';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { AIAgentIcon } from '../../icons/AIAgentIcon';
import { CreateAIAgentOptions } from '../../models';

import styles from './AIAgentType.module.scss';

type AIAgentProps = {
  type: CreateAIAgentOptions;
  handleClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  isSelected: boolean;
};

export const AIAgentType = ({
  type,
  handleClick,
  isSelected,
}: AIAgentProps) => {
  const { t } = useTranslation();

  return (
    <button
      className={cn(styles.container, {
        [styles.selected]: isSelected,
      })}
      onClick={handleClick}
    >
      <div className={styles.header}>
        <AIAgentIcon type={type} size={16} />

        <Typography variant="body-medium" color="var(--text-default-black)">
          {t(`ai_agents.types.${type}.title`)}
        </Typography>
      </div>
      <Typography color="var(--text-default-gray)">
        {t(`ai_agents.types.${type}.description`)}
      </Typography>
    </button>
  );
};
