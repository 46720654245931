import { CreateAIAgentOptions } from './models';

export const CREATE_AI_AGENT_OPTIONS: CreateAIAgentOptions[] = [
  'engagement',
  'activation',
  'upsell',
  'debt_collection',
  'support',
  'import',
] as const;
