import Access from '@/components/atoms/Icons/Access';
import Billing from '@/components/atoms/Icons/Billing';
import Clock from '@/components/atoms/Icons/Clock';
import Danger from '@/components/atoms/Icons/Danger';
import { HumanAgents } from '@/components/atoms/Icons/HumanAgents';
import Invoices from '@/components/atoms/Icons/Invoices';
import InvitationsIcon from '@/components/atoms/Icons/Move';
import Notification from '@/components/atoms/Icons/Notification';
import Privacy from '@/components/atoms/Icons/Privacy';
import Profile from '@/components/atoms/Icons/Profile';

export const personalSettings = [
  {
    icon: <Profile size={16} />,
    title_key: 'pages.profile',
    link: '/profile/information',
  },
  {
    icon: <InvitationsIcon size={16} />,
    title_key: 'pages.invitations_simple',
    link: '/profile/invitations',
  },
  {
    icon: <Notification size={16} />,
    title_key: 'pages.notifications_simple',
    link: '/profile/notifications',
  },
];

export const accountSettings = [
  {
    icon: <Profile />,
    title_key: 'common.information',
    link: '/account/information',
  },
  {
    icon: <Billing />,
    title_key: 'pages.billing_simple',
    link: '/account/billing',
  },
  {
    icon: <Invoices />,
    title_key: 'pages.invoices',
    link: '/account/invoices',
  },
  {
    icon: <Access />,
    title_key: 'permissions.manage_access',
    link: '/account/manage',
  },
  {
    icon: <HumanAgents />,
    title_key: 'pages.human_agents',
    link: '/account/human-agents',
  },
  {
    icon: <Clock size={16} />,
    title_key: 'pages.activity_logs',
    link: '/account/activity-logs',
  },
  {
    icon: <Privacy />,
    title_key: 'common.data_privacy',
    link: '/account/data-privacy',
  },
  {
    icon: <Danger size={16} />,
    title_key: 'common.danger_zone',
    link: '/account/danger',
  },
];
