import { useCallback, useEffect, useMemo, useState } from 'react';

import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import Button from '@/components/atoms/Button/Button/Button';
import IconButton from '@/components/atoms/IconButton/IconButton';
import Attachment from '@/components/atoms/Icons/Attachment';
import Close from '@/components/atoms/Icons/Close';
import Emoji from '@/components/atoms/Icons/Emoji';
import Response from '@/components/atoms/Icons/Response';
import { EmojiPicker } from '@/components/organisms/EmojiPicker/EmojiPicker';
import { MODAL_CANNED_RESPONSES } from '@/components/organisms/Modals/ModalConductor';
import UploadCard from '@/modules/humanChat/components/Conversation/MessageArea/FileUploadChatBox/UploadCard/UploadCard';
import { useTemplateMessage } from '@/modules/humanChat/hooks/useTemplateMessage';
import { pushModal } from '@/redux/modals/actions';
import { preventClickThrough } from '@/util/util';

import QuickResponses from './QuickResponses';
import { useChatBox } from '../ChatBox/useChatBox';

import styles from '../ChatBox/ChatBox.module.scss';

const ICON_SIZE = 20;

const FileUploadChatBox = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [emojiPicker, setEmojiPicker] = useState(false);
  const { showTemplateMessage } = useTemplateMessage();

  const {
    files,
    isClosed,
    isActive,
    inputRef,
    isLoading,
    filteredOptions,
    isButtonDisabled,
    showQuickResponses,
    handleClick,
    handleChange,
    changeInputTo,
    handleKeyPress,
    handleKeyDown,
    setShowQuickResponses,
    handleOnRemoveFile,
    handlePaste,
    getRootProps,
    getInputProps,
    open,
    isDragActive,
  } = useChatBox();

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const filesToRender = useMemo(
    () =>
      files.map((file, idx) => {
        const fileName = file.name;
        if (file.type?.includes('image')) {
          return (
            <div
              className={cn(styles.thumbsWrapper, {
                [styles.noHover]: isLoading,
              })}
              key={file.path}
            >
              <div className={styles.thumbs}>
                <img
                  src={file.preview}
                  alt={fileName}
                  onLoad={() => {
                    URL.revokeObjectURL(file.preview);
                  }}
                  onDragStart={(e) => preventClickThrough(e)}
                />

                {!isLoading && (
                  <Close
                    color="var(--icon-default-white)"
                    onClick={(e) => handleOnRemoveFile(e, idx, fileName)}
                    onKeyDown={(e) => handleOnRemoveFile(e, idx, fileName)}
                    className={styles.closeIcon}
                    size={16}
                  />
                )}
              </div>
            </div>
          );
        }
        return (
          <UploadCard
            fileName={file.name?.split('.')[0]}
            fileType={file.name?.split('.').pop()}
            handleClose={(e) => handleOnRemoveFile(e, idx, fileName)}
            key={file.path}
          />
        );
      }),
    [files, handleOnRemoveFile, isLoading]
  );

  const handleQuickResponseClick = useCallback(
    (text: string) => {
      setShowQuickResponses(false);
      changeInputTo(text);
    },
    [changeInputTo, setShowQuickResponses]
  );

  const shouldDisableButtons = showTemplateMessage || !isActive;
  return (
    <>
      {emojiPicker && (
        <EmojiPicker
          className={styles.emojiPicker}
          onClickAway={() => {
            setEmojiPicker(false);
          }}
          onEmojiClick={({ emoji }) => {
            inputRef.current.innerHTML += emoji;
            setEmojiPicker(false);
          }}
          width="100%"
          height={200}
        />
      )}

      <div
        {...getRootProps({
          className: cn({
            [styles.inputWrapper]: true,
            [styles.isDragActive]: isDragActive,
          }),
        })}
      >
        <div
          className={cn({
            [styles.hide]: isDragActive,
          })}
        >
          <div
            ref={inputRef}
            className={cn(styles.input, {
              [styles.isClosed]: isClosed,
            })}
            aria-label="input"
            role="textbox"
            contentEditable={!shouldDisableButtons}
            tabIndex={0}
            onKeyUp={handleKeyPress}
            onKeyDown={handleKeyDown}
            onInput={handleChange}
            onPaste={handlePaste}
            data-testid="file-upload-chat-box"
            data-placeholder={t('chatBox.open')}
          />
          {files && <div className={styles.fileZone}>{filesToRender}</div>}
          <div
            className={cn(styles.chatBoxFooter, {
              [styles.isClosed]: isClosed,
              [styles.isDragAccept]: isDragActive,
            })}
          >
            <ul className={styles.icons}>
              <IconButton
                onClick={() =>
                  dispatch(pushModal(MODAL_CANNED_RESPONSES, { changeInputTo }))
                }
                ariaLabel={t('chatBox.quick_responses')}
                tooltip={t('chatBox.quick_responses')}
                disabled={shouldDisableButtons}
              >
                <Response size={ICON_SIZE} />
              </IconButton>

              <IconButton
                onClick={open}
                tooltip={t('chatBox.attach_file')}
                ariaLabel={t('chatBox.attach_file')}
                disabled={shouldDisableButtons}
              >
                <Attachment size={ICON_SIZE} />
              </IconButton>
              <IconButton
                onClick={() => setEmojiPicker(true)}
                tooltip={t('emojiPicker.emoji_panel')}
                ariaLabel={t('emojiPicker.emoji_panel')}
                disabled={shouldDisableButtons}
              >
                <Emoji size={ICON_SIZE} color="var(--icon-default-gray)" />
              </IconButton>
            </ul>

            <input {...getInputProps()} />
            <Button
              isLoading={isLoading}
              disabled={isButtonDisabled}
              onClick={handleClick}
            >
              {t('chatBox.send')}
            </Button>
          </div>
        </div>
        {isDragActive && (
          <div className={styles.dropFile}>{t('chatBox.drop_files')}</div>
        )}
      </div>

      {showQuickResponses && (
        <QuickResponses
          handleClick={handleQuickResponseClick}
          filteredOptions={filteredOptions}
        />
      )}
    </>
  );
};

export default FileUploadChatBox;
