import { useCallback } from 'react';

import CircularProgress from '@mui/material/CircularProgress/CircularProgress';
import { Trans } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';

import { Banner } from '@/components/atoms/Banner/Banner';
import { useAccount } from '@/hooks/useAccount';
import { useAllRules } from '@/hooks/useAllRules';
import useBrains from '@/hooks/useBrains';
import useFeatureFlag from '@/hooks/useFeatureFlag';
import { EventName } from '@/models/segment';
import { Rule } from '@/modules/rules/model';
import { popModal } from '@/redux/modals/actions';
import { trackEvent } from '@/segment/segment';

import ModalDelete from './ModalDelete';
import ModalWarn from './ModalWarn';

import styles from './ModalBrainDelete.module.scss';
type Props = {
  brainName: string;
  brainId: string;
  onDeleted?: () => void;
};

const ModalBrainDelete = ({ brainName, brainId, onDeleted }: Props) => {
  const navigate = useNavigate();
  const { deleteBrain } = useBrains();
  const { ai_agents } = useFeatureFlag();
  const { slug } = useAccount();
  const dispatch = useDispatch();
  const { status, rulesConnected, noRules } = useAllRules({ brainId });

  const handleDelete = useCallback(() => {
    deleteBrain(brainId, {
      onSettled: () => {
        if (onDeleted) {
          onDeleted();
        }
        dispatch(popModal());
      },
    });
    trackEvent(EventName.ClickDeleteBrain, { brain_id: brainId });
  }, [brainId, deleteBrain, dispatch, onDeleted]);

  const subtitle = (
    <Trans
      i18nKey={`brains.delete_warning${ai_agents ? '_ai_agent' : ''}`}
      values={[brainName]}
    />
  );

  if (noRules) {
    return <ModalDelete subtitle={subtitle} confirm onDelete={handleDelete} />;
  }

  return (
    <ModalWarn>
      {status === 'pending' ? (
        <span className={styles.spinner}>
          <CircularProgress size={40} />
        </span>
      ) : (
        <Banner<Partial<Rule>>
          relativePosition
          variant="critical"
          referenceProp="rule_id"
          references={rulesConnected}
          labelKey="name"
          onRefClick={(rule_id) => {
            const deskId = rulesConnected.find(
              (rule) => rule.rule_id === rule_id
            )?.desk_id;
            dispatch(popModal());
            navigate(`/${slug}/environments/${deskId}/rules/${rule_id}`);
          }}
        >
          <Trans
            i18nKey={'brains.delete_warning_not_empty'}
            values={{ brain: brainName }}
          />
        </Banner>
      )}
    </ModalWarn>
  );
};

export default ModalBrainDelete;
