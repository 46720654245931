import { useCallback, useEffect, useState } from 'react';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';

import ChatLogs from '@/components/atoms/Icons/ChatLogs';
import Dialogs from '@/components/atoms/Icons/Dialogs';
import Entities from '@/components/atoms/Icons/Entities';
import Intents from '@/components/atoms/Icons/Intents';
import Version from '@/components/atoms/Icons/Version';
import Webhooks from '@/components/atoms/Icons/Webhooks';
import { Icon as IconProps } from '@/models/icon';
import { selectAccountSlug, selectBrainId } from '@/redux/session/selectors';

import { ConversationIcon } from '../../icons/ConversationIcon';
import { KnowledgeIcon } from '../../icons/KnowledgeIcon';
import { OverviewIcon } from '../../icons/OverviewIcon';
import { ReviewIcon } from '../../icons/ReviewIcon';
import { SetupIcon } from '../../icons/SetupIcon';
import { SidebarIcon } from '../../icons/SidebarIcon';

import styles from './NavigationSidebar.module.scss';

type BaseItem = { id: string; icon: React.FC<IconProps> };
type NavOption = BaseItem & { nestedItems?: BaseItem[] };

const NAV_OPTIONS: NavOption[] = [
  {
    id: 'overview',
    icon: OverviewIcon,
    nestedItems: [],
  },
  {
    id: 'setup',
    icon: SetupIcon,
    nestedItems: [],
  },
  {
    id: 'knowledge',
    icon: KnowledgeIcon,
    nestedItems: [],
  },
  {
    id: 'conversation',
    icon: ConversationIcon,
    nestedItems: [
      {
        id: 'dialogs',
        icon: Dialogs,
      },
      {
        id: 'intents',
        icon: Intents,
      },
      {
        id: 'entities',
        icon: Entities,
      },
      {
        id: 'webhooks',
        icon: Webhooks,
      },
    ],
  },
  {
    id: 'review',
    icon: ReviewIcon,
    nestedItems: [
      {
        id: 'logs',
        icon: ChatLogs,
      },
      {
        id: 'versions',
        icon: Version,
      },
    ],
  },
] as const;

export const NavigationSidebar = () => {
  // Custom hooks
  const { t } = useTranslation();
  const location = useLocation();

  // Redux selectors
  const slug = useSelector(selectAccountSlug);
  const brainId = useSelector(selectBrainId);

  // Local state
  const [open, setOpen] = useState(true);
  const [openSubmenus, setOpenSubmenus] = useState({});

  // Local variables
  const basePath = `/${slug}/ai-agents/${brainId}`;
  const pathSegments = location.pathname.split('/').filter(Boolean);

  // Assuming "conversation" and "dialogs" are always in the 4th and 6th positions respectively
  const parentSegment = pathSegments[3]; // "conversation" in your example
  const nestedSegment = pathSegments[4]; // "dialogs" in your example

  // Handlers
  const handleDrawerToggle = () => {
    setOpen((prev) => !prev);
  };

  const toggleSubmenu = (id: string) => {
    // Toggle the specific submenu independently
    setOpenSubmenus((prev) => ({
      ...prev,
      // Always open the submenu if the sidebar is closed
      [id]: open ? !prev[id] : true,
    }));
  };

  const isNestedActive = useCallback(
    (nestedItems: BaseItem[]) =>
      nestedItems.some((item) => item.id === nestedSegment),
    [nestedSegment]
  );

  // Set submenu open state based on URL on initial load
  useEffect(() => {
    const initialOpenSubmenus = {};
    NAV_OPTIONS.forEach((option) => {
      if (isNestedActive(option.nestedItems || [])) {
        initialOpenSubmenus[option.id] = true;
      }
    });

    setOpenSubmenus((prev) => ({
      ...prev,
      ...initialOpenSubmenus,
    }));
  }, [isNestedActive]);

  return (
    <nav className={cn(styles.nav, { [styles['nav--open']]: open })}>
      <div className={styles.toggle}>
        <IconButton onClick={handleDrawerToggle}>
          <SidebarIcon />
        </IconButton>
      </div>

      <ul
        className={cn({
          [styles.list]: true,
          [styles['list--collapsed']]: !open,
        })}
      >
        {NAV_OPTIONS.map((option) => {
          const parentActive = parentSegment === option.id;
          const hasNestedItems = option.nestedItems?.length > 0;
          const nestedActive = isNestedActive(option.nestedItems);
          const isCurrentOptionActive =
            (parentActive && !hasNestedItems) || (!open && nestedActive);
          const color = isCurrentOptionActive ? 'blue' : 'gray';
          const variant = isCurrentOptionActive
            ? 'body-medium'
            : 'body-regular';

          return (
            <li key={option.id}>
              <NavLink
                className={({ isActive }) =>
                  cn(styles.list__item, {
                    [styles['list__item--active']]:
                      (isActive && option.nestedItems.length === 0) ||
                      (!open && nestedActive),
                    // Logic for parent active state
                    [styles['list__item--parent-active']]:
                      !open && nestedActive,
                  })
                }
                to={`${basePath}/${option.id}`}
                onClick={(e) => {
                  // Opem menu and submenu if item with children is selected
                  // debugger;
                  if (option.nestedItems.length > 0) {
                    e.preventDefault();
                    // debugger;
                    toggleSubmenu(option.id);

                    if (!open) {
                      // debugger;
                      setOpen(true);
                    }
                  }
                }}
              >
                <option.icon color={`var(--icon-default-${color})`} />

                <Typography
                  variant={variant}
                  color={`var(--text-default-${color})`}
                >
                  {t(`ai_agents.navigation.${option.id}`)}
                </Typography>

                {option.nestedItems.length > 0 &&
                  (openSubmenus[option.id] ? (
                    <ExpandLess
                      className={styles.expandIcon}
                      fontSize="small"
                      htmlColor="var(--icon-default-gray)"
                    />
                  ) : (
                    <ExpandMore
                      className={styles.expandIcon}
                      fontSize="small"
                      htmlColor="var(--icon-default-gray)"
                    />
                  ))}
              </NavLink>

              {option.nestedItems.length > 0 && open && (
                <Collapse
                  in={openSubmenus[option.id]}
                  timeout="auto"
                  unmountOnExit
                >
                  <ul className={cn(styles.list, styles.isNested)}>
                    {option.nestedItems.map((nestedOption) => {
                      const isCurrentNestedOptionActive =
                        nestedSegment === nestedOption.id;
                      const color = isCurrentNestedOptionActive
                        ? 'blue'
                        : 'gray';
                      const variant = isCurrentNestedOptionActive
                        ? 'body-medium'
                        : 'body-regular';

                      return (
                        <li key={nestedOption.id}>
                          <NavLink
                            className={({ isActive }) =>
                              isActive
                                ? `${styles.list__item} ${styles['list__item--active']}`
                                : styles.list__item
                            }
                            to={`${basePath}/${option.id}/${nestedOption.id}`}
                          >
                            <nestedOption.icon
                              color={`var(--icon-default-${color})`}
                            />
                            <Typography
                              variant={variant}
                              color={`var(--text-default-${color})`}
                            >
                              {t(`common.${nestedOption.id}`)}
                            </Typography>
                          </NavLink>
                        </li>
                      );
                    })}
                  </ul>
                </Collapse>
              )}
            </li>
          );
        })}
      </ul>
    </nav>
  );
};
